<template>
  <div>
    <a-button v-if="selectedTeacher" type="primary" @click="showAccessDrawer"
      ><a-icon type="setting" />Ajouter niveau d'accées</a-button
    >
    <!-- START ACCESS SCHED DRAWER -->
    <a-drawer
      v-if="visibleAccessDrawer"
      title="Ajouter des acceés à l'enseignant "
      width="50%"
      :visible="visibleAccessDrawer"
      @close="() => (visibleAccessDrawer = false)"
    >
      <a-form :form="form" layout="vertical" hide-required-mark>
        <a-row>
          <a-col :span="24">
            <a-form-item :label="$t('emploi.classe')" class="pb-0">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                :loading="loading"
                @change="
                  (val) => {
                    handleClassChange(val);
                  }
                "
                v-decorator="[
                  'classeRoom',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('emploi.choisirClasse'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('emploi.choisirClasse')"
              >
                <a-select-option
                  v-for="classe in filtredClasses"
                  :key="classe._id"
                  :value="classe._id"
                >
                  {{ classe.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="loadingSubjects">
          <a-col :span="24">
            <div>
              <a-spin tip="Chargement..." :spinning="!!selectedClassroom">
                <div class="spin-content">
                  <a-alert
                    message="Veuillez choisir une classe parmi celles affectées à l'enseignant."
                    type="info"
                    show-icon
                  />
                </div>
              </a-spin>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-else>
          <a-col :span="12">
            <a-form-item :label="$t('emploi.matiere')">
              <a-tree
                v-model="checkedSubKeys"
                checkable
                :selected-keys="selectedSubKeys"
                default-expand-all
                @check="onCheck"
              >
                <a-tree-node
                  :key="m.key"
                  v-for="m in subjectTeeData"
                  :checkable="false"
                >
                  <template slot="title">
                    <span
                      >{{ m.name }}
                      <a-tag color="blue">{{
                        getModuleTypeLabel(m.type)
                      }}</a-tag>
                    </span>
                  </template>
                  <a-tree-node
                    :key="s._id"
                    :title="s.name"
                    v-for="s in m.subjects"
                  />
                </a-tree-node>
              </a-tree>
            </a-form-item>
          </a-col>
          <a-col :span="1">
            <a-divider type="vertical" />
          </a-col>
          <a-col :span="11">
            <a-form-item :label="$t('scolarite.appreciation')">
              <a-tree
                v-model="checkedAppKeys"
                checkable
                :selected-keys="selectedAppKeys"
                default-expand-all
                @check="onAppCheck"
              >
                <a-tree-node :key="m.key" v-for="m in appTreeData">
                  <template slot="title">
                    <span
                      >{{ m.name }}
                      <a-tag color="blue">{{
                        getModuleTypeLabel(m.type)
                      }}</a-tag>
                    </span>
                  </template>
                  <a-tree-node
                    :key="s._id"
                    :title="s.name"
                    v-for="s in m.sousModules"
                  />
                </a-tree-node>
              </a-tree>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24"> </a-col>
        </a-row>
      </a-form>

      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          :style="{ marginRight: '8px' }"
          @click="() => (visibleAccessDrawer = false)"
        >
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          type="primary"
          @click="handleSubmit"
          :loading="loadingAddBtn"
          :disabled="loadingAddBtn"
        >
          {{ $t("action.modifier") }}
        </a-button>
      </div>
    </a-drawer>
    <!-- END ACCESS SCHED DRAWER -->
  </div>
</template>
<script>
import apiClient from "@/services/axios";
export default {
  props: {
    selectedTeacher: {
      required: true,
    },
    selectedTrimester: {
      required: true,
    },
    listSubjects: {
      required: true,
    },
  },
  data() {
    return {
      visibleAccessDrawer: false,
      loadingAddBtn: false,
      form: this.$form.createForm(this),
      filtredClasses: [],
      loadingSubjects: true,
      data: [],
      loading: false,
      selectedClassroom: null,
      checkedSubKeys: [],
      selectedSubKeys: [],
      subjectTeeData: [],
      appTreeData: [],
      checkedAppKeys: [],
      selectedAppKeys: [],
    };
  },
  mounted() {},
  methods: {
    getModuleTypeLabel(type) {
      switch (type) {
        case "MP":
          return "Module pédagogique";
        case "MT":
          return "Module pilote";
        case "MS":
          return "Module spécifique";
        default:
          return "--";
      }
    },

    async handleClassChange(value) {
      try {
        this.loadingSubjects = true;
        this.selectedClassroom = value;
        this.checkedSubKeys = [];
        this.checkedAppKeys = [];
        // First API call to get subjects and appAccessFlat
        let query = {
          query: {
            teacher: this.selectedTeacher,
            trimester: this.selectedTrimester,
            classroom: value,
          },
        };
        const classLevel = this.filtredClasses.find(
          (c) => c._id == value
        ).level;

        // Use Promise.all to execute both API calls concurrently
        const [allSubjectsResponse, accessTreeResponse] = await Promise.all([
          apiClient.post("/teacherAccess/all", query),
          apiClient.get(
            `/teacherAccess/accesstree/${this.selectedTrimester}/${classLevel}`
          ),
        ]);

        // Process the first API response
        if (allSubjectsResponse.data[0]) {
          this.checkedSubKeys = allSubjectsResponse.data[0].subjects.map(
            (subject) => subject._id
          );
          this.checkedAppKeys = allSubjectsResponse.data[0].appAccessFlat;
        }

        // Process the second API response
        if (accessTreeResponse.data && accessTreeResponse.data.length > 0) {
          this.subjectTeeData = accessTreeResponse.data;
          this.appTreeData = accessTreeResponse.data.filter(
            (m) => m.type != "other"
          );
        } else {
          this.subjectTeeData = [];
          this.appTreeData = [];
        }
      } catch (error) {
        console.error("Error while loading data:", error);
      } finally {
        // End the loading state
        this.loadingSubjects = false;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async showAccessDrawer() {
      if (!this.selectedTeacher) {
        this.$message.error(this.$t("emploi.choisirEnseignant"));
        return;
      }
      this.selectedClassroom = null;
      this.loading = true;
      this.loadingSubjects = true;
      this.visibleAccessDrawer = true;
      apiClient
        .get(`/teachers/classroomAffectations/${this.selectedTeacher}`)
        .then((res) => {
          this.filtredClasses = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      let tmpAppAccess = [];
      //make appration access compatible to the modal from tree componenet
      let chekedModules = this.checkedAppKeys.filter((key) => key[0] === "M");

      this.subjectTeeData.forEach((module) => {
        try {
          const sousModulesIds = module.sousModules.map((sm) => sm._id);
          const moduleChecked = chekedModules.includes(module.key);
          const sousModuleChecked = sousModulesIds.some((id) =>
            this.checkedAppKeys.includes(id)
          );

          if (moduleChecked || sousModuleChecked) {
            let sousModuleIncluded = sousModulesIds.filter((id) =>
              this.checkedAppKeys.includes(id)
            );
            const unitAppAccess = {
              module: module._id,
              haveSubMod: sousModulesIds.length > 0,
              canAppModule:
                sousModuleIncluded.length == module.sousModules.length,
              sousModules: sousModuleIncluded,
            };

            tmpAppAccess.push(unitAppAccess);
          }
        } catch (error) {
          console.error(error);
        }
      });
      this.form.validateFields((err, values) => {
        //check existing access

        //end check
        if (!err) {
          this.loadingAddBtn = true;
          values.teacher = this.selectedTeacher;
          values.trimester = this.selectedTrimester;
          values.subjects = this.checkedSubKeys.filter(
            (id) => !id.startsWith("M")
          );
          values.appAccess = tmpAppAccess;
          apiClient
            .put(`/teacherAccess`, values)
            .then((res) => {
              this.$message.success("Niveau d'acceés ajouté avec succeés");
              this.$emit("reload");
            })
            .catch((e) => {
              console.error(e);
              this.$message.error("Niveau d'acceés non ajouté");
            })
            .finally(() => (this.loadingAddBtn = false));
        }
      });
    },
    onCheck(checkedSubKeys) {
      this.checkedSubKeys = checkedSubKeys;
    },
    onAppCheck(checkedAppKeys) {
      this.checkedAppKeys = checkedAppKeys;
    },
  },
};
</script>
<style>
[data-kit-theme="default"] .stat-card .ant-card-body {
  padding: 10px !important;
  background: #eee;
  border-radius: 2px;
}
</style>
