<template>
  <a-table :columns="columns" :data-source="access" :pagination="false">
    <span slot="subjects" slot-scope="text">
      <a-tag color="green" v-for="s in text" :key="s._id">
        {{ s.name }}
      </a-tag>
    </span>
  </a-table>
</template>
<script>
export default {
  props: {
    access: {
      required: false,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("bulletin.listeClasses"),
          dataIndex: "classeRoom.name",
          key: "classeRoom",
        },
        {
          title: this.$t("bulletin.matieres"),
          dataIndex: "subjects",
          key: "subjects",
          scopedSlots: {
            customRender: "subjects",
          },
        },
      ],
    };
  },
  mounted() {},
};
</script>
